/*
  To be used as a child of <drb-select>.

  ```
    <drb-select name="favorite-colors" value="red" multiple searchable>
      <drb-option value="red">Red</drb-option>
      <drb-option value="blue">Blue</drb-option>
      <drb-option value="green">Green</drb-option>
    </drb-select>
  ```
*/

import { LitElement, unsafeCSS, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { html, literal } from 'lit/static-html.js';
import { customElement, property, query, state } from 'lit/decorators.js';
import checkIcon from '~/assets/icons/v2-check.svg?raw';
import styles from './drb-option.scss?inline';
import { DrbOptionGroup } from '../drb-option-group/drb-option-group';

@customElement('drb-option')
class DrbOption extends LitElement {
  static styles = unsafeCSS(styles);
  @query('[data-option]') optionContainer: HTMLElement;

  @state() current = false; // the user has keyed into the option, but hasn't selected it yet (shows a highlight)
  @state() selected = false; // the option is selected (shows a checkmark)
  @state() listOrder = 0;
  @state() selectedOrder = 0;

  optionGroup = this.closest<DrbOptionGroup>('drb-option-group');
  optionGroupLabel = this.optionGroup?.label || '';

  @property()
  label = this.textContent?.trim() || "";

  @property({ reflect: true })
  value = this.textContent?.trim() || "";

  // Draws the option in a disabled state, preventing selection.
  @property({ type: Boolean, reflect: true })
  disabled = false;

  connectedCallback(): void {
    super.connectedCallback();
    this.setAttribute('role', 'option');
  }

  updated(changedProperties: Map<string, any>) {
    if (changedProperties.has('value')) {
      // Ensure the value is a string. Allows consumers users to pass numbers
      // instead of requiring them to cast the value to a string.
      if (typeof this.value !== 'string') {
        this.value = String(this.value);
      }

      if (this.value.includes('|')) {
        console.error(`drb-option values cannot include pipes '|'. All pipes have been replaced with underscores.`, this);
        this.value = this.value.replace(/\|/g, '_');
      }
    }
  }

  scrollIntoView(arg?: boolean | ScrollIntoViewOptions): void {
    if (this.optionContainer?.scrollIntoView) {
      this.optionContainer.scrollIntoView(arg);
    }
  }

  render() {
    return html`
      <div
        data-option
        style=${`order: ${this.listOrder}`}
        class=${classMap({
          option: true,
          'option--current': this.current,
          'option--disabled': this.disabled,
          'option--selected': this.selected
        })}
      >
        <span class="option__content">
          <span class="option__text">
            <slot></slot>
          </span>
        </span>

        <span
          class="option__check-icon"
          ?hidden=${!this.selected}
        >
          ${unsafeHTML(checkIcon)}
        </span>
      </div>
    `;
  }
}

export { DrbOption };
